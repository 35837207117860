import type { FirebaseApp } from "firebase/app";
import { getApps, initializeApp } from "firebase/app";
import {
  Auth as FirebaseAuth,
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  UserCredential,
  sendPasswordResetEmail,
  updatePassword,
  updateEmail,
  updateProfile,
  User as AuthUser,
} from "firebase/auth";
import { getFirestore, Firestore, doc, getDoc } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_MEASUREMENT_ID,
};

/**
 * @description FirebaseAppを返す
 */
export const getFirebaseApp = (): FirebaseApp | undefined => {
  // if (typeof window === "undefined") return; // バックエンドで実行されないようにする
  return getApps()[0] || initializeApp(firebaseConfig);
};

export const functions = getFunctions(getFirebaseApp(), "asia-northeast1");

export const getFbUserToken = async () => {
  const fbUser = firebaseUser();
  return fbUser ? await fbUser.getIdToken(true) : "";
};
export const getFbUserUid = (): string => {
  return firebaseUser()?.uid || "";
};

export const functionsPayload = async (
  action: string,
  params: any,
  id?: string
) => {
  const token = await getFbUserToken();
  return { token, action, params, id };
};

/**
 * @description FirebaseAuthを返す
 */
export const getFirebaseAuth = (): FirebaseAuth => {
  return getAuth(getFirebaseApp());
};

export const db = (): Firestore | null => {
  const app = getFirebaseApp();
  if (!app) return null;

  return getFirestore(app);
};

export const storageUploadFile = async (file: File, directory: string = "") => {
  const storage = getStorage();
  const storageRef = ref(storage, `${directory}${file.name}`);
  const result = await uploadBytes(storageRef, file);
  return { result, ref: storageRef };
};
export const storageDownloadFile = async (path: string) => {
  const storage = getStorage();
  const storageRef = ref(storage, path);
  return getDownloadURL(storageRef);
};

/**
 * @description メールアドレスとパスワードで登録
 */
export const register = async (
  email: string,
  password: string
): Promise<UserCredential | { code: string; message: string }> => {
  const auth = getFirebaseAuth();
  const result = await createUserWithEmailAndPassword(
    auth,
    email,
    password
  ).catch((error) => {
    const { code, message } = error.code;
    return { code, message };
  });
  return result;
};

/**
 * @description メールアドレスとパスワードでログイン
 */
export const login = async (email: string, password: string) => {
  // FirebaseAuthを取得する
  const auth = getFirebaseAuth();
  // メールアドレスとパスワードでログインする
  const result = await signInWithEmailAndPassword(auth, email, password);
  return result;
};

/**
 * @description メールアドレスでパスワード再設定メール送信
 */
export const forgot = async (email: string) => {
  const auth = getFirebaseAuth();
  const result = await sendPasswordResetEmail(auth, email).catch((e) => e);
  return result;
};

export const updateAuthPassword = async (password: string) => {
  const auth = getFirebaseAuth();
  if (!auth.currentUser) return;
  const result = await updatePassword(auth.currentUser, password);
  return result;
};

const isCommonPages = (pathname: string) => pathname.startsWith("/docs");

const redirectToSignin = () => {
  // 非ログインかつ、ログイン系ページでなければ
  const { pathname } = window.location;
  if (isCommonPages(pathname)) return;

  if (!pathname.startsWith("/signin")) {
    // ログイン画面へ
    window.location.href = "/signin";
  }
};

const redirectToMain = () => {
  //　ログイン中かつ、ログイン系ページなら
  const { pathname } = window.location;
  if (isCommonPages(pathname)) return;

  if (pathname.startsWith("/signin")) {
    // ログイン後TOPへ
    window.location.href = "/tools";
  }
};

// ログイン状態の検知
export const listenAuthState = (dispatch: any) => {
  const auth = getFirebaseAuth();
  return auth.onAuthStateChanged(async (user) => {
    if (user) {
      // User is signed in.
      dispatch({
        type: "login",
        payload: {
          user,
        },
      });
      redirectToMain();
    } else {
      // User is signed out.
      // ...
      dispatch({
        type: "logout",
      });
      redirectToSignin();
    }
  });
};

/**
 * @description ログアウトさせる
 */
export const logout = () => {
  const auth = getFirebaseAuth();
  auth.signOut().then(() => {
    window.location.reload();
  });
};

export const firebaseUser = (): AuthUser | null => {
  const auth = getFirebaseAuth();
  return auth.currentUser;
};

export const firestoreUser = async () => {
  const uid = firebaseUser()?.uid;
  const database = db();
  if (!database) return null;

  const q = doc(database, "users", uid as string);
  const d = await getDoc(q);
  return { id: d.id, ...d.data() };
};
