import React from "react";
import { TailSpin } from "react-loader-spinner";

type Props = {
  show: boolean;
};

const Loading: React.FC<Props> = ({ show }) => {
  return show ? (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        position: "fixed",
        top: 0,
        left: 0,
        background: "rgba(255,255,255, 0.4)",
      }}
      className="d-flex align-items-center justify-content-center"
    >
      <TailSpin height="100" width="100" color="#ecb312" ariaLabel="loading" />
    </div>
  ) : (
    <></>
  );
};

export default Loading;
