const initialState = {};

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "login":
      return action.payload.user;
    case "logout":
      return initialState;
    default:
      return state;
  }
};

const authReducer = {
  initialState,
  reducer,
};

export default authReducer;
