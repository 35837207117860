import React, { useReducer, useEffect, useState, useCallback } from "react";
import { AppProps } from "next/app";
import AuthContext from "lib/AuthContext";
import authReducer from "lib/authReducer";
import { listenAuthState } from "lib/firebase";
import "styles/globals.scss";
import { ToastContainer, Slide } from "react-toastify";
import AppContext from "lib/AppContext";
import Loading from "components/common/Loading";
import "react-toastify/dist/ReactToastify.css";
import { UserRepository } from "domains/User";
import { gtmID } from "utils/Gtm";
import Gtm, { GtmID } from "components/common/Gtm";
import "fontsource-noto-sans-jp";
import { useRouter } from "next/router";

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const [state, dispatch] = useReducer(
    authReducer.reducer,
    authReducer.initialState
  );
  const [isFetching, setIsFetching] = useState(true);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    listenAuthState(dispatch);
  }, []);

  // TODO: stateにユーザー情報が含まれるため、これでなにかする

  // TODO: validate apiでcompanyIDがなければリダイレクトする仕様に変更?
  // 初回の企業設定
  const handleChangeRoute = useCallback(async () => {
    const res = await UserRepository.me();
    // エラー(未ログイン)か、企業IDがあれば終了
    if (!res.item.id || res.item.companyID) return;

    // 会社未所属の場合
    //　ログイン中かつ、会社作成画面でなければ
    const { pathname } = window.location;
    const companyCreatePath = "/settings/company";
    if (!pathname.startsWith(companyCreatePath)) {
      // 会社作成・招待受諾画面へ
      router.push(companyCreatePath);
    }
  }, [router]);

  useEffect(() => {
    router.events.on("routeChangeComplete", handleChangeRoute);

    handleChangeRoute();
    return () => {
      router.events.off("routeChangeComplete", handleChangeRoute);
    };
  }, [router, handleChangeRoute]);

  const tick = () => setIsFetching(false);
  // 1 秒間は Loading 画面を表示する
  useEffect(() => {
    const timerId = setInterval(tick, 1000);
    return () => clearInterval(timerId);
  }, []);

  if (isFetching) {
    return <></>;
  }

  return (
    <AppContext.Provider value={{ load, setLoad }}>
      <AuthContext.Provider value={state}>
        {gtmID && <Gtm gtmID={gtmID as GtmID} />}
        <Component {...pageProps} />
        <Loading show={load} />
        <ToastContainer transition={Slide} />
      </AuthContext.Provider>
    </AppContext.Provider>
  );
}

export default MyApp;
