import MyError from "domains/MyError";
import { functions, functionsPayload } from "lib/firebase";
import { httpsCallable } from "firebase/functions";
import Company from "./Company";
import { Pages } from "domains/common";
const callable = httpsCallable(functions, "users");

export default class User {
  constructor(
    public id: string,
    public companyID: string,
    public displayName: string,
    public subscribe: boolean,
    public company?: Company
  ) {}
}
export type UserResponse = User;
export type UserResponses = {
  items: UserResponse[];
  pages: Pages;
};

export class UserFactory {
  static createFromResponseObject(res: UserResponse): User {
    return new User(
      res.id,
      res.companyID,
      res.displayName,
      res.subscribe,
      res.company
    );
  }
}

export class UserRepository {
  static async me(): Promise<{ item: User }> {
    const result = await callable(await functionsPayload("me", {}));
    const user = result.data as UserResponse;
    return { item: UserFactory.createFromResponseObject(user) };
  }
  static async meUpdate(
    displayName: string,
    subscribe: boolean
  ): Promise<{ item: User } | MyError> {
    const result: { data: any } = await callable(
      await functionsPayload("meUpdate", { displayName, subscribe })
    );
    if ("error" in result.data) return result.data as MyError;

    const user = result.data as UserResponse;
    return { item: UserFactory.createFromResponseObject(user) };
  }
  static async meUpdateEmail(
    email: string,
    currentEmail: string
  ): Promise<{ item: User } | MyError> {
    const result: { data: any } = await callable(
      await functionsPayload("meUpdateEmail", { email, currentEmail })
    );
    if ("error" in result.data) return result.data as MyError;

    const user = result.data as UserResponse;
    return { item: UserFactory.createFromResponseObject(user) };
  }
  static async index(
    page?: number,
    limit?: number
  ): Promise<{ items: User[]; pages: Pages } | MyError> {
    const result: { data: any } = await callable(
      await functionsPayload("index", { page, limit })
    );
    if ("error" in result.data) return result.data as MyError;

    const response = result.data as UserResponses;
    const { items: users, pages } = response;
    return {
      items: users.map((value) => {
        return UserFactory.createFromResponseObject(value);
      }),
      pages,
    };
  }
  static async find(id: string): Promise<{ item: User } | MyError> {
    const result: { data: any } = await callable(
      await functionsPayload("find", {}, id)
    );
    if ("error" in result.data) return result.data as MyError;
    const user = result.data as UserResponse;
    return { item: UserFactory.createFromResponseObject(user) };
  }
  static async meDestroy(): Promise<{ item: User } | MyError> {
    const result: { data: any } = await callable(
      await functionsPayload("meDestroy", {})
    );
    if ("error" in result.data) return result.data as MyError;

    const user = result.data as UserResponse;
    return { item: UserFactory.createFromResponseObject(user) };
  }
}
