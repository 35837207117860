import React, { Dispatch } from "react";

const AppContext = React.createContext(
  {} as {
    load: boolean;
    setLoad: Dispatch<React.SetStateAction<boolean>>;
  }
);

export default AppContext;
